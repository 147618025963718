@font-face {
  font-family: "db_heavent";
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot");
  src: url("./assets/fonts/db_heavent_v3.2-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.svg#db_heavent")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'sriracharegular';
    src: url('./assets/fonts/sriracha-regular-webfont.eot');
    src: url('./assets/fonts/sriracha-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/sriracha-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/sriracha-regular-webfont.woff') format('woff'),
         url('./assets/fonts/sriracha-regular-webfont.ttf') format('truetype'),
         url('./assets/fonts/sriracha-regular-webfont.svg#sriracharegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'noricanregular';
    src: url('./assets/fonts/norican-regular-webfont.eot');
    src: url('./assets/fonts/norican-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/norican-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/norican-regular-webfont.woff') format('woff'),
         url('./assets/fonts/norican-regular-webfont.ttf') format('truetype'),
         url('./assets/fonts/norican-regular-webfont.svg#noricanregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
  font-family: 'promptbold';
  src: url('./assets/fonts/prompt-bold-webfont.eot');
  src: url('./assets/fonts/prompt-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/prompt-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/prompt-bold-webfont.woff') format('woff'),
       url('./assets/fonts/prompt-bold-webfont.ttf') format('truetype'),
       url('./assets/fonts/prompt-bold-webfont.svg#promptbold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'promptregular';
  src: url('./assets/fonts/prompt-regular-webfont.eot');
  src: url('./assets/fonts/prompt-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/prompt-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/prompt-regular-webfont.woff') format('woff'),
       url('./assets/fonts/prompt-regular-webfont.ttf') format('truetype'),
       url('./assets/fonts/prompt-regular-webfont.svg#promptregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'promptthin';
  src: url('./assets/fonts/prompt-thin-webfont.eot');
  src: url('./assets/fonts/prompt-thin-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/prompt-thin-webfont.woff2') format('woff2'),
       url('./assets/fonts/prompt-thin-webfont.woff') format('woff'),
       url('./assets/fonts/prompt-thin-webfont.ttf') format('truetype'),
       url('./assets/fonts/prompt-thin-webfont.svg#promptthin') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'promptmedium';
  src: url('./assets/fonts/prompt-medium-webfont.eot');
  src: url('./assets/fonts/prompt-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/prompt-medium-webfont.woff2') format('woff2'),
       url('./assets/fonts/prompt-medium-webfont.woff') format('woff'),
       url('./assets/fonts/prompt-medium-webfont.ttf') format('truetype'),
       url('./assets/fonts/prompt-medium-webfont.svg#promptmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

$shadowCard: 0 3px 3px rgba(0, 0, 0, 0.22);

* {
  outline: none;
}

body,
html {
  scroll-behavior: smooth;

  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}


.text-0-4 { font-size: 0.4rem;}
.text-0-6 { font-size: 0.6rem;}
.text-0-8 { font-size: 0.8rem;}
.text-1 { font-size: 1rem;}
.text-1-1 { font-size: 1.1rem;}
.text-1-2 { font-size: 1.2rem;}
.text-1-4 { font-size: 1.4rem;}
.text-1-6 { font-size: 1.6rem;}
.text-1-8 { font-size: 1.8rem;}
.text-2 { font-size: 2rem;}

.px-05 { padding: 0 5px; }
.px-07 { padding: 0 7px; }
.px-1 { padding: 0 10px; }
.px-2 { padding: 0 20px; }

.my-05 { margin: 5px 0; }
.my-1 { margin: 10px 0; }
.my-2 { margin: 20px 0; }

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

// .font-sriracharegular { font-family: 'sriracharegular';}
// .font-noricanregular { font-family: 'noricanregular';}
.font-sriracharegular { font-family: 'db_heaventmed';}
.font-noricanregular { font-family: 'db_heaventbold';}

.flex { display: flex; }
.flex-g-1 { flex-grow: 1; }

.m-auto { margin: auto; }
.items-center { align-items: center; }
.items-baseline { align-items: baseline; }
.content-center { justify-content: center; }
.content-between { justify-content: space-between; }

.pointer-events-none { pointer-events: none; }

.underconstruction {
  span {
    font-family: 'db_heavent';
  }
}

// Main -----------------------------------------------------//
.container {
  overscroll-behavior: auto;
}

// WS card --------------------------------------------------//
.ws-page {
  padding: 10px 0;
  max-width: 768px;
  margin: auto;

  .head-section {
    .ic-history {
      margin-left: .2rem;
    }
  }
}

.card-section {
  margin: 10px 0 0;
  .amount-expire-day {
    // position: absolute;
    // top: 0;
    // right: 0;
    margin-top: -10px;
    margin-right: -10px;
    background-color: #BE4328;
    padding: .2rem .8rem;
    border-radius: 0 20px 0 0;
    p {
      font-size: 1rem;
      color: #ffffff;
    }
  }
  .slider-wrapper {
    .ws-card {
      box-shadow: $shadowCard;
      display: flex;
      border-radius: 20px;
      border-top: 1px solid #efefef;

      .amount-expire-day {
        display: none;
      }

      ._left {
          width: 52%;
          border-radius: 20px;
          padding: 1rem 10px;
          color: #ffffff;
          border-radius: 20px 0 0 20px;
          background-color: #d9d9d9;

          ._header {
            h1 { 
              margin-right: 5px;
              margin-bottom: .8rem;
            }
          }

          h1 {
            font-size: 2rem;
            color: #ffffff;
          }

          p {
            color: #ffffff;
          }
    
      }
      ._right {
          width: 48%;
          padding: 10px;
          color: #8E8E8E;
          // background-color: #efe8de;
          background-color: #ffffff;
          border-radius: 0 20px 20px 0;
          position: relative;
          display: grid;

          ._body {
            margin: 10px 0;
            h1 {
              visibility: hidden;
              position: relative;
              &::after {
                content: '-';
                visibility: visible;
                position: absolute;
                left: 0;
                right: 0;
              }
            }
          }

          .txt-gray {
              color: #8E8E8E;
          }
          h1 {
            line-height: .7;
            font-size: 3.6rem;
          }
          h2 {
            line-height: .8;
            font-size: 1.6rem;
          }
          ._footer {
            display: flex;
            .left, .right {
              padding: 0 2px;
              text-align: center;
              flex-grow: 1;
              display: grid;
              align-items: center;
              p {
                &:last-child {
                  visibility: hidden;
                  position: relative;
                  &::after {
                    content: '-';
                    visibility: visible;
                    position: absolute;
                    left: 0;
                    right: 0;
                  }
                }
              }
            }
            
          }
      }
    }

    .selected {
      .ws-card {
        ._right {
          ._body {
            h1 {
              visibility: visible;
              &::after {
                content: '';
                visibility: hidden;
              }
            }
          }
          ._footer {
            .left, .right {
              p {
                visibility: visible;
                &:last-child {
                  &::after {
                    content: '';
                    visibility: hidden;
                    position: absolute;
                  }
                }
              }
            }
          }
        }
        .amount-expire-day {
          display: block;
        }

        &.status-none {
          ._left {
            background-color: #d9d9d9;
            h1, p {
              color: #ffffff;
            }
          }
          .txt-hilight {
            color: #d9d9d9;
          }
          ._right {
            ._body {
              color: #ffffff;
            }
            ._footer {
              color: #ffffff;
              .left {
                border-right: 1px solid #ffffff;
              }
            }
          }
        }
        &.status-current, &.status-ccb2b-current {
          ._left {
            // background-color: #373C67;
            background-color: #FFA600;
            h1, p {
              color: #ffffff;
            }
          }
          .txt-hilight {
            color: #FFA600;
            // color: #373C67;
          }
          ._right {
            ._footer {
              .left {
                border-right: 1px solid #FFA600;
                // border-right: 1px solid #373C67;
              }
            }
          }
        }
        &.status-ready, &.status-ccb2b-ready {
          ._left {
            // background-color: #3B6737;
            background-color: #008000;
            h1, p {
              color: #ffffff;
            }
          }
          .txt-hilight {
            color: #008000;
            // color: #3B6737;
          }
          ._right {
            ._footer {
              .left {
                border-right: 1px solid #008000;
                // border-right: 1px solid #3B6737;
              }
            }
          }
        }
        &.status-expired, &.status-ccb2b-expired , &.status-used, &.status-ccb2b-used {
          ._left {
            background-color: #808080;
            // background-color: #8E8E8E;
            h1, p {
              color: #ffffff;
            }
          }
          .txt-hilight {
            color: #808080;
            // color: #8E8E8E;
          }
          ._right {
            ._footer {
              .left {
                border-right: 1px solid #808080;
                // border-right: 1px solid #8E8E8E;
              }
            }
          }
        }
        &.status-processing, &.status-ccb2b-processing {
          ._left {
            // background-color: #521C53;
            background-color: #FFA600;
            h1, p {
              color: #ffffff;
            }
          }
          .txt-hilight {
            color: #FFA600;
            // color: #521C53;
          }
          ._right {
            ._footer {
              .left {
                border-right: 1px solid #FFA600;
                // border-right: 1px solid #521C53;
              }
            }
          }
        }
      }
    }
  }
}

// Summary List Card
.summary-list {
  .summary-item {
    margin-bottom: .4rem;
    img {
      width: 1.5rem;
      margin-right: .4rem;
    }
    p {
      font-family: 'db_heavent';
      font-size: 1.2rem;
      line-height: .8;
    }
  }
}

// Tabs
.tabs-ws {
  .react-tabs__tab-list {
    border-bottom-color: #d9d9d9;
    display: flex;
  }
  .react-tabs__tab {
    width: 33%;
    text-align: center;
    color: #c6c6c6;
    flex-grow: 1;
  }
  .react-tabs__tab--selected {
    border: none;
    border-bottom: 5px solid #77C7BE;
    color: #000000;
  }
}

// Carousel
.carousel {
  &:last-child {
    display: none;
  }
  &.carousel-slider {
    padding-bottom: 2rem;
  }

  .slider-wrapper {
    padding-bottom: .8rem;
  }

  .control-dots {
    .dot {
      background-color: #c6c6c6;
      box-shadow: none;
      margin: 0 4px;
      width: 6px;
      height: 6px;

      &:hover {
        opacity: .3;
      }
      &.selected {
        opacity: 1;
      }
    }
  }

}

// Accordion 
.accordion {
  border: none;
  .accordion__heading {
    .accordion__button {
      background-color: transparent;
      padding: 0;
      text-align: right;
      &[aria-expanded='true'] {
        &::before {
          transform: rotate(225deg);
        }
      }
      &::before {
        transform: rotate(45deg);
        border-width: 1px;
        border-color: #595959;
        margin-right: 6px;
      }
    }
  }
  .accordion__panel {
    padding: 0;
    .row {
      &:first-child {
        padding-top: 10px;
        border-top: 1px solid #ccc;
      }
    }
  }
}

// Summary Item 
.ws-summary {

  .item {
    padding: 10px 0;

    h1 {
      font-size: 1.6rem;
      margin-bottom: 6px;
      color: #4c4c4c;
      line-height: 1;
    }
    ._body {
      font-family: 'db_heavent';
      padding: 15px 18px;
      background-color: #EEEEEE;
      border-radius: 10px;
      .row {
        display: flex;
        margin-bottom: 8px;
      }
      
      ._left, ._right {
        width: 50%;
        color: #595959;
      }

      ._right {
        text-align: right;
        span {
          font-family: 'db_heaventbold';
        }
      }
    }
  }

  .drilldown {
    .datetime {
      font-size: 1rem;
      color: #a9a9a9;
    }
  }
}

.ws-filter {
  display: flex;
  align-items: center;
  > * {
    flex-grow: 1;
  }
  label {
    text-align: right;
    padding-right: 6px;
  }
}

// React Select
.react-select-fillter {
  width: 20%;
  font-size: 1.2rem;
}

// Scan QR Code
.scanqrcode-box { 
  ._footer {
    display: flex;
    flex-direction: column;
    bottom: 70px;
    .btn-round {
      width: 240px;
      padding: 18px 0;
      &:first-child {
        margin-bottom: 10px;
      }
      h3, h4 {
        font-size: 1.4rem;
      }
    }
  }
}
  

// Responsive
@media (max-width: 375px) and (max-height: 667px) {
  .scanqrcode-box { 
    ._footer {
      bottom: 0;
      flex-direction: row;
      padding: 10px 20px;
      height: 64px;
      .btn-round {
        width: 160px;
        padding: 12px 0;
        border-radius: 10px;
        &:first-child {
          margin: auto;
        }
        h3, h4 {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .head-section {
    .date-section {
      p {
        &.text-1-8 {
          font-size: 1.6rem;
        }
      }
    }
  }
  p {
    font-size: 1.2rem;
  }
  .card-section  { 
    .ws-card {
      ._left {
        ._header {
          h1 {
            font-size: 1.6rem;
          }
        }
      }
      ._right {
        h1 {
          font-size: 2.8rem;
        }
        h2 {
          font-size: .8rem;
        }
        ._footer {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .summary-list {
    .summary-item {
      p {
        font-size: 1rem;
      }
    }
  }

  .tabs-ws {
    .react-tabs__tab {
      font-size: 1.2rem;
    }
  }

  .ws-summary {
    .item {
      h1 {
        font-size: 1.4rem;
      }
      ._body {
        .row {
          font-size: 1.2rem;
        }
      }
    }
  }

  .scanqrcode-box { 
    ._footer {
      .btn-round {
        width: 130px;
        h3, h4 {
          font-size: 1rem;
        }
      }
    }
  }
}

// .ReactModalPortal { display:  none; }